export { matchers } from './client-matchers.js';

export const components = [
	() => import("../../src/routes/__layout.svelte"),
	() => import("../runtime/components/error.svelte"),
	() => import("../../src/routes/admin.svelte"),
	() => import("../../src/routes/btc.svelte"),
	() => import("../../src/routes/commits/[hash].svelte"),
	() => import("../../src/routes/commits.svelte"),
	() => import("../../src/routes/enter.svelte"),
	() => import("../../src/routes/event/[event].svelte"),
	() => import("../../src/routes/explorer.svelte"),
	() => import("../../src/routes/fiat.svelte"),
	() => import("../../src/routes/index.svelte"),
	() => import("../../src/routes/login/github/[username].svelte"),
	() => import("../../src/routes/login.svelte"),
	() => import("../../src/routes/logout.svelte"),
	() => import("../../src/routes/profile.svelte"),
	() => import("../../src/routes/svelte.svelte")
];

export const dictionary = {
	"": [[0, 10], [1]],
	"admin": [[0, 2], [1]],
	"btc": [[0, 3], [1]],
	"commits": [[0, 5], [1]],
	"enter": [[0, 6], [1]],
	"explorer": [[0, 8], [1]],
	"fiat": [[0, 9], [1]],
	"login": [[0, 12], [1]],
	"logout": [[0, 13], [1]],
	"profile": [[0, 14], [1]],
	"svelte": [[0, 15], [1]],
	"login/github/[username]": [[0, 11], [1]],
	"commits/[hash]": [[0, 4], [1]],
	"event/[event]": [[0, 7], [1]]
};